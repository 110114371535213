import { ActionButton, Modal, Text } from "components/commons";
import ImportModal from "components/modals/import-modal/import-modal";
import locale from "localization";
import { useState } from "react";
import { parseCSVFile } from "utils/object.utils";
import { isNumeric, isNumericV2, validateMobileNumberFormat } from "utils/text.utils";
// import { useCallback } from "react";
import { useApi, useModal } from "hooks";
import { parseNumber } from "utils";
import { validateFuelCardsByCardNumbers } from "../../../../apis/fuel-card.api";

const ImportFleetCardModal = ({
  request = {},
  saveFleetCardsCb,
  stationInputs,
  index,
  modifyForm,
  setFleetCardSelected,
  fleetCardSelected,
  ...importModal
}) => {
  const [csvData, setCSVData] = useState([]);
  const resultModal = useModal();

  const { request: validateFuelCardsByCardNumbersRequest } = useApi({
    api: validateFuelCardsByCardNumbers,
    pageError: true,
  });

  const validateFile = async ({ file }, onParse) => {
    let items = await parseCSVFile(file, [
      {
        text: "Card Number",
        key: "cardNumber",
        validation: (text, row) => {
          if (text && text.length === 16 && isNumeric(text)) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Card Number",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Card Type (No Specific/Driver Specific/Vehicle Specific/Driver and Vehicle Specific)",
        key: "cardType",
        validation: (text, row) => {
          if (
            [
              "Driver Specific",
              "Vehicle Specific",
              "Driver and Vehicle Specific",
              "No Specific",
            ].includes(text)
          ) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Card Type",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Diesel",
        key: "diesel",
        validation: (text, row) => {
          if (["1", "0", ""].includes(text)) {
            if (
              text !== "1" &&
              row.gas91 !== "1" &&
              row.gas95 !== "1" &&
              row.gas97 !== "1" &&
              row.lubes !== "1"
            ) {
              return {
                error: true,
                message: "No product allowed",
                errorType: "code",
              };
            }
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Diesel",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Gas91",
        key: "gas91",
        validation: (text, row) => {
          if (["1", "0", ""].includes(text)) {
            if (
              text !== "1" &&
              row.diesel !== "1" &&
              row.gas95 !== "1" &&
              row.gas97 !== "1" &&
              row.lubes !== "1"
            ) {
              return {
                error: true,
                message: "No product allowed",
                errorType: "code",
              };
            }
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Gas 91",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Gas95",
        key: "gas95",
        validation: (text, row) => {
          if (["1", "0", ""].includes(text)) {
            if (
              text !== "1" &&
              row.diesel !== "1" &&
              row.gas91 !== "1" &&
              row.gas97 !== "1" &&
              row.lubes !== "1"
            ) {
              return {
                error: true,
                message: "No product allowed",
                errorType: "code",
              };
            }
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Gas 95",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Gas97",
        key: "gas97",
        validation: (text, row) => {
          if (["1", "0", ""].includes(text)) {
            if (
              text !== "1" &&
              row.diesel !== "1" &&
              row.gas95 !== "1" &&
              row.gas91 !== "1" &&
              row.lubes !== "1"
            ) {
              return {
                error: true,
                message: "No product allowed",
                errorType: "code",
              };
            }
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Gas 97",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Lubricants",
        key: "lubes",
        validation: (text, row) => {
          if (["1", "0", ""].includes(text)) {
            if (
              text !== "1" &&
              row.diesel !== "1" &&
              row.gas95 !== "1" &&
              row.gas91 !== "1" &&
              row.gas97 !== "1"
            ) {
              return {
                error: true,
                message: "No product allowed",
                errorType: "code",
              };
            }
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Lubricants",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Driver's License ID",
        key: "driverLicenseId",
        validation: (text, row) => {
          if (
            ["Driver Specific", "Driver and Vehicle Specific"].includes(row.cardType) &&
            (!text || text?.length !== 11)
          ) {
            return {
              error: true,
              message: "Invalid Driver's License ID",
            };
          } else {
            return {
              error: false,
              message: "",
            };
          }
        },
      },
      {
        text: "Driver's Name",
        key: "driver",
        validation: (text, row) => {
          if (["Driver Specific", "Driver and Vehicle Specific"].includes(row.cardType) && !text) {
            return {
              error: true,
              message: "Invalid Driver's Name",
            };
          } else {
            return {
              error: false,
              message: "",
            };
          }
        },
      },
      {
        text: "Driver Contact Number (Optional)",
        key: "mobileNumber",
        validation: (text, row) => {
          if (text && !validateMobileNumberFormat(text)) {
            return {
              error: true,
              message: "Invalid Driver Contact Number",
            };
          } else {
            return {
              error: false,
              message: "",
            };
          }
        },
      },
      {
        text: "Vehicle Plate Number",
        key: "plateNumber",
        validation: (text, row) => {
          if (["Vehicle Specific", "Driver and Vehicle Specific"].includes(row.cardType) && !text) {
            return {
              error: true,
              message: "Invalid Vehicle Plate Number",
            };
          } else {
            return {
              error: false,
              message: "",
            };
          }
        },
      },
      {
        text: "Vehicle Brand & Model",
        key: "model",
        validation: (text, row) => {
          if (["Vehicle Specific", "Driver and Vehicle Specific"].includes(row.cardType) && !text) {
            return {
              error: true,
              message: "Invalid Vehicle Brand & Model",
            };
          } else {
            return {
              error: false,
              message: "",
            };
          }
        },
      },
      {
        text: "Vehicle Color/Remarks",
        key: "vehicleRemarks",
        validation: (text, row) => {
          if (["Vehicle Specific", "Driver and Vehicle Specific"].includes(row.cardType) && !text) {
            return {
              error: true,
              message: "Invalid Vehicle Color/Remarks",
            };
          } else {
            return {
              error: false,
              message: "",
            };
          }
        },
      },
      {
        text: "LOCQPay Limit (Blank if No Limit)",
        key: "locqpayLimit",
        validation: (text, row) => {
          if (text.length === 0 || (text.length > 0 && isNumericV2(text))) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid LOCQPay Limit",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Transaction Limit (Blank if No Limit)",
        key: "transactionLimit",
        validation: (text, row) => {
          if (text.length === 0 || (text.length > 0 && isNumeric(text))) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: "Invalid Transaction Limit",
              errorType: "code",
            };
          }
        },
      },
      {
        text: "Remarks",
        key: "remarks",
        validation: (text, row) => {
          return {
            error: false,
            message: "",
          };
        },
      },
    ]);

    const cardNumbers = items?.passed?.map((x) => x.cardNumber);
    if (cardNumbers.length > 0) {
      const duplicates = cardNumbers.reduce((prev, val, index) => {
        if (!prev[val]) prev[val] = [];
        prev[val].push(index + 2);
        return prev;
      }, {});

      Object.entries(duplicates).forEach(([key, value]) => {
        if (value.length > 1) {
          value.forEach((row) => {
            items.errors.push(`Row ${row}: ${key} Duplicate card number`);
          });
        }
      });

      const res = await validateFuelCardsByCardNumbersRequest({
        cardNumbers: cardNumbers.join(","),
      });

      if (res.length > 0) {
        for (const card of res) {
          const rowNumber = cardNumbers.findIndex((x) => x === card.cardNumber);

          const isAlreadyInvalid = items.errors.some((error) =>
            error.startsWith(`Row ${rowNumber + 2}`)
          );
          if (!isAlreadyInvalid) {
            items.errors.push(
              `Row ${rowNumber + 2}: ${card.cardNumber} Card number already exists`
            );
          }
        }
      }
    }

    if (items.errors.length > 0) {
      items.passed = [];
    }

    if (items.passed.length > 50) {
      items.passed = [];
      items.errors.push("Maximum limit of 50 fleet cards reached");
    }

    items.passed.map((item) => {
      item.diesel = item.diesel === "1";
      item.gas91 = item.gas91 === "1";
      item.gas95 = item.gas95 === "1";
      item.gas97 = item.gas97 === "1";
      item.lubes = item.lubes === "1";

      item.locqpayLimit =
        item.locqpayLimit.length > 0
          ? parseNumber(item.locqpayLimit) > 0
            ? parseNumber(item.locqpayLimit)
            : null
          : null;
      item.transactionLimit =
        item.transactionLimit.length > 0
          ? parseNumber(item.transactionLimit) > 0
            ? parseNumber(item.transactionLimit)
            : null
          : null;

      switch (item.cardType) {
        case "Vehicle Specific":
          item.cardType = "vehicle-specific";
          break;
        case "Driver Specific":
          item.cardType = "driver-specific";
          break;
        case "Driver and Vehicle Specific":
          item.cardType = "driver-vehicle-specific";
          break;
        default:
          item.cardType = "any";
      }

      return item;
    });

    return items;
  };

  const handleLoadData = () => {
    saveFleetCardsCb(csvData);
  };

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          handleLoadData(index);
          setCSVData([]);
          resultModal.close();
        }}
      >
        {resultModal.content}
        <ActionButton
          right={!resultModal.isSuccess}
          center
          items={[
            {
              text: locale.gotIt,
              onClick: () => {
                handleLoadData();
                setCSVData([]);
                resultModal.close();
              },
              primary: true,
            },
          ]}
        />
      </Modal>
      <ImportModal
        title={locale.importFleetCardByCSV}
        {...importModal}
        loading={request.loading}
        onSubmit={async (file) => {
          const parsedFile = await validateFile(file);
          const errors = [];
          if (parsedFile.hasBlankRows) {
            errors.push({
              title: "Other error:",
              items: ["There are blank rows or columns in your file"],
            });
          }

          console.log("parseFiled", parsedFile);

          if (parsedFile?.invalidFormat) {
            resultModal.show({
              title: locale.invalidImport,
              isSuccess: false,
              content: <ErrorImport errors={parsedFile.errors} correctData={parsedFile.passed} />,
            });
          } else if (parsedFile?.passed.length) {
            if (parsedFile.errors.length) {
              setCSVData(parsedFile.passed);
              resultModal.show({
                title: locale.invalidImport,
                isSuccess: false,
                content: <ErrorImport errors={parsedFile.errors} correctData={parsedFile.passed} />,
              });
            } else {
              setCSVData(parsedFile.passed);
              resultModal.show({
                title: locale.importSuccess,
                isSuccess: true,
                content: (
                  <div
                    style={{
                      width: "400px",
                      textAlign: "center",
                    }}
                  >
                    <locale.Populate
                      text={locale.importSuccessMessageAddFleetAccountStations}
                      items={[
                        <b>
                          {parsedFile.passed.length <= 1
                            ? `${parsedFile.passed.length} fleet card`
                            : `${parsedFile.passed.length} fleet cards`}
                        </b>,
                      ]}
                    />
                  </div>
                ),
              });
            }

            importModal.close();
          } else {
            importModal.close();

            resultModal.show({
              title: locale.invalidImport,
              content: <ErrorImport errors={parsedFile.errors} correctData={parsedFile.passed} />,
            });
          }
        }}
      >
        <Text
          subtitle
          style={{
            margin: "0px 0px 20px 0px",
          }}
        >
          {locale.followTheFollowingColumnOrderAndLeaveRow1}
          <locale.Populate
            text={locale.downloadASampleTemplate}
            items={[
              <span
                colored
                style={{
                  color: "#753bbd",
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const win = window.open(
                    "https://locq-assets.s3.ap-southeast-1.amazonaws.com/Fleet+Card+Template.csv",
                    "_blank"
                  );
                  win.focus();
                }}
              >
                {locale.sampleTemplate}
              </span>,
            ]}
          />
        </Text>
      </ImportModal>
    </div>
  );
};

const ErrorImport = ({ errors = [], data = [], correctData = [] }) => {
  const getRow = errors.map((item) => item.match(/row\s(\d+)/i)?.[1] ?? "");
  const checkIfHasData = getRow[0] !== "" ? getRow : [];
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Text
          style={{
            color: "black",
          }}
        >
          {locale.invalidImportCSV}
        </Text>
      </div>
      {checkIfHasData.length > 0 ? (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          ></div>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "45%",
              }}
            >
              <Text subtitle>{locale.invalidEntries}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {errors.length > 10
                  ? errors.length
                  : `${errors.length} (rows no. ${getRow.join(", ")})`}
              </Text>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "30%",
              }}
            >
              <Text subtitle>{locale.otherError}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "70%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {" "}
                {errors.slice(0, 5).join(", \n")}
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportFleetCardModal;
