const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user",
  Mops: "ms-pricing/mops",
  Fleet: "ms-fleet/fleet",
  GetUserProfile: "ms-profile/user",
  UserInvite: "ms-profile/user/invite",
  UpdateUserAccess: "ms-profile/user/user-access",
  GetUsers: "ms-profile/user/users",
  GetUserFleets: "ms-fleet/user/fleet",
  GetBooking: "ms-booking/booking",
  GetDrivers: "ms-fleet/driver/fleet",
  BuyFuel: "ms-fleet/buy-fuel",
  Driver: "ms-fleet/driver",
  GetVehicles: "ms-fleet/vehicle/fleet",
  Vehicle: "ms-fleet/vehicle",
  GetCashIns: "ms-fleet/cashin",
  ManualCashIn: "ms-fleet/cashin/manual",
  FuelBalance: "ms-fleet/fuel-balance/fleet",
  FuelCode: "ms-fleet/fuel-code",
  FuelCard: "ms-fleet/fuel-card",
  VirtualStation: "ms-fleet/virtual-station",
  User: "ms-fleet/user",
  UserAttributes: "ms-fleet/user/attributes",
  UserAttribute: "ms-fleet/user/attribute",
  Redemption: "ms-fleet/redemption/redemption-items",
  RedemptionNew: "ms-fleet/redemption/platform-type",
  Station: "ms-fleet/station",
  StationPricing: "ms-pricing/station",
  StationAccounts: "ms-fleet/station/account",
  Depot: "ms-pricing/depot",
  Wallet: "ms-fleet/wallet",
  Customers: "ms-fleet/user/user-details",
  GenerateReport: "ms-fleet/report/v3",
  GenerateReportList: "ms-report/report",
  UpdateReport: (id) => `ms-report/report/${id}`,
  GeneratePricingReport: "ms-pricing/report",
  GeneratePricingReportv2: "ms-pricing/report/v2",
  GeneratePricingAdminReport: "ms-pricing/report/admin",
  GenerateReportAdmin: "ms-fleet/report/admin/v3",
  GenerateProfileAdmin: "ms-profile/report/admin/v2",
  GenerateBookingReportAdmin: "ms-booking/report/admin",
  CampaignVouchers: "ms-fleet/voucher-campaign",
  CampaignDiscounts: "ms-fleet/discount-campaign",
  Campaigns: "#",
  AccountTypesList: "ms-profile/account-type",
  UniqueVoucherClaims: "ms-fleet/voucher-campaign/claims",
  VoucherCampaignCreators: "ms-fleet/voucher-campaign/creators",
  ProductConversion: "ms-fleet/product-conversion",
  GetPriceChanges: "ms-fleet/fleet/audit-trail",
  GetUserByMobileNumber: "ms-profile/user/mobile-number",
  GetFuelBalanceUser: "ms-fleet/fuel-balance/user",
  GetPriceBuildup: "ms-fleet/station/price-build-up",
  NewCustomerVouchers: "ms-fleet/new-customer-voucher/claims",
  GetSeaoilPriceTool: "ms-pricing/station/seaoil/price-tool",
  GetFleetPriceTool: "ms-fleet/fleet/price-tool",
  GetStationPricing: (stationId) => `ms-pricing/station/${stationId}/pricing-data`,
  FleetRedemption: "ms-fleet/redemption",
  KYCSubmission: "ms-profile/kyc",
  Prepayment: "ms-fleet/voucher-campaign/claims/prepayments",
  UpdateCustomer: (userId) => `ms-profile/user/${userId}/customer`,
  SettlementHistory: "ms-pricing/settlement/dealer",
  SettlementGroup: "ms-pricing/settlement/group",
  SettlementOilcoHistory: "ms-pricing/settlement/oilco",
  SettlementRedemption: "ms-pricing/redemption/settlement",
  PricingDetails: (fleetId) => `ms-pricing/virtual-station/fleet/${fleetId}`,
  PricingVirtualStation: "ms-pricing/virtual-station",
  RFIDTopups: "ms-fleet/rfid/topup",
  PricingMovement: `ms-pricing/price-movement`,
  Payments: "ms-fleet/payment",
  VoidBuyFuel: "ms-fleet/buy-fuel/void",
  GetStationNegotiatedDiscount: "ms-pricing/station/station-negotiated-discount",
  Charging: "ms-pricing/charging",
  ChargingDetails: (chargingId) => `ms-pricing/charging/${chargingId}/details`,
  ChargingById: (chargingId) => `ms-pricing/charging/${chargingId}`,
  UpdateMobileNumber: `ms-profile/user/update`,
  MerchantPayments: "ms-fleet/merchant/payment",
  OutRightDiscountCampaign: "ms-fleet/outright-discount-campaign",
  CardManagement: "ms-fleet/fuel-card",
  ShortName: "ms-fleet/fleet/short-names",
};

export default ApiPath;
