import {
  AccountStatus,
  AttributeUploadResponse,
  AuditTrailAction,
  Balance,
  BuyFuelStatus,
  CashInChannel,
  CashInStatus,
  ChargingStatus,
  CustomerStatus,
  DispenseType,
  DriverStatus,
  FleetAccountType,
  FleetCardType,
  FuelCodesStatus,
  FuelCodeType,
  IntroductoryPromoOffer,
  InvoicingSetUp,
  LubeServSite,
  PaymentStatus,
  PLBPostedBy,
  PLBTypes,
  Products,
  RedeemLimitType,
  ReportType,
  Role,
  SettlementStatus,
  ShoulderedBy,
  StationClass,
  Status,
  UserStatus,
  VehicleStatus,
  VolumeCap,
  VoucherCampaignCurrency,
  VoucherCampaignType,
} from "enums";
import VoucherCampaignGroup from "enums/campaign-group";
import ClaimMethod from "enums/claim-method";
import Currency from "enums/currency";
import CustomerStatusRemarks from "enums/customer-status-remarks";
import KYCSubmissionStatus from "enums/kyc-submission-status";
import OilChangeType from "enums/oil-change-type";
import PayWithLOCQPayStatus from "enums/pay-with-locqpay-status";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import PlatformType from "enums/platform-type";
import RedemptionStatus from "enums/redemption-status";
import RFIDPaymentMethod from "enums/rfid-payment-method";
import RFIDStatus from "enums/rfid-status";
import RFIDType from "enums/rfid-type";
import ThirdPartyVoucherCode from "enums/third-party-voucher-code";
import { VoidPaymentValue } from "enums/void-payment-value";
import { VoidTransactionValue } from "enums/void-transaction-value";
import VoucherStatus from "enums/voucher-status";
import VoucherType from "enums/voucher-type";
import VoucherTypeOptions from "enums/voucher-type-options";
import VoucherTypeSetting from "enums/voucher-type-setting";
import locale from "localization";

export const prettifyDriverStatus = (driverStatus) => {
  return (
    {
      [DriverStatus.Active]: locale.active,
      [DriverStatus.Inactive]: locale.inactive,
    }[driverStatus] || ""
  );
};

export const prettifyVehicleStatus = (vehicleStatus) => {
  return (
    {
      [VehicleStatus.Active]: locale.active,
      [VehicleStatus.Inactive]: locale.inactive,
    }[vehicleStatus] || ""
  );
};

export const prettifyDispenseType = (dispenseType) => {
  return (
    {
      [DispenseType.Vehicle]: locale.vehicle,
      [DispenseType.DrumContainer]: locale.drumContainer,
    }[dispenseType] || ""
  );
};

export const prettifyCashInStatus = (cashInStatus) => {
  return (
    {
      [CashInStatus.Success]: locale.success,
      [CashInStatus.Completed]: locale.completed,
      [CashInStatus.Cancelled]: locale.cancelled,
      [CashInStatus.Pending]: locale.pending,
      [CashInStatus.Failed]: locale.failed,
      [CashInStatus.Expired]: locale.expired,
      [CashInStatus.Voided]: locale.voided,
    }[cashInStatus] || ""
  );
};

export const prettifyProduct = (productCode) => {
  return (
    {
      [Products.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Products.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Products.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Products.Diesel]: locale.diesel,
      [Products.Lubes]: locale.lubricants,
    }[productCode] || ""
  );
};

export const prettifyCashInChannel = (cashinChannel) => {
  return (
    {
      [CashInChannel.ManualPaymaya]: locale.manualPaymaya,
      [CashInChannel.ManualBankDeposit]: locale.manualBankDeposit,
      [CashInChannel.Card]: locale.card,
      [CashInChannel.OnlineBanking]: locale.onlineBank,
      [CashInChannel.LocqRebate]: locale.locqRebate,
      [CashInChannel.BNPL]: locale.bnpl,
      [CashInChannel.NewCustomerVoucher]: locale.newCustomerVoucher,
      [CashInChannel.Voucher]: locale.voucher,
      [CashInChannel.Referral]: locale.referral,
      [CashInChannel.EWallet]: locale.ewallet,
      [CashInChannel.OTC]: locale.otcBank,
      [CashInChannel.GCash]: locale.gcash,
      [CashInChannel.Billease]: locale.billease,
      [CashInChannel.Cybersource]: locale.cybersource,
      [CashInChannel.Brankas]: locale.brankas,
      [CashInChannel.SevenEleven]: locale.sevenEleven,
      [CashInChannel.BDO]: locale.BDO,
      [CashInChannel.Unionbank]: locale.unionBank,
      [CashInChannel.BPIOnlineBank]: locale.bpi,
      [CashInChannel.Seaoil]: locale.seaoil,
      [CashInChannel.ExpressPay]: locale.expressPay,
      [CashInChannel.ECPay]: locale.ecpay,
      [CashInChannel.HLhuillier]: locale.hlhuillier,
      [CashInChannel.RDPawnshop]: locale.rDPawnshop,
      [CashInChannel.TrueMoney]: locale.trueMoney,
      [CashInChannel.BPI]: locale.BPI,
      [CashInChannel.LocqPromo]: "LOCQ PROMO",
      [CashInChannel.WithholdingTaxRefund]: locale.withholdingTaxRefund,
      [CashInChannel.IntroductoryPromo]: locale.introductoryPromoRebate,
      [CashInChannel.ShopeePay]: locale.shopeePay,
      [CashInChannel.DragonPay]: locale.dragonPay,
      [CashInChannel.Rebate]: locale.rebate,
      [CashInChannel.PayWithPeso]: locale.payWithPeso,
      [CashInChannel.OnlineBankingBrankas]: locale.onlineBankBrankas,
      [CashInChannel.OnlineBankDragonPay]: locale.onlineBankDragonpay,
      [CashInChannel.OnlineBankBPI]: "Online bank (BPI)",
      [CashInChannel.LocqPayDiscount]: locale.locqPayDiscount,
      [CashInChannel.SeaoilReciprocity]: locale.seaoilReciprocity,
      [CashInChannel.SeaoilVip]: "Seaoil VIP",
      [CashInChannel.VipLOCQPay]: locale.vipPointsRedemption,
    }[cashinChannel] || ""
  );
};

export const prettifyFuelCodes = (fuelCodesStatus) => {
  return (
    {
      [FuelCodesStatus.Active]: locale.active,
      [FuelCodesStatus.Inactive]: locale.inactive,
      [FuelCodesStatus.Scheduled]: locale.scheduled,
      [FuelCodesStatus.Expired]: locale.expired,
      [FuelCodesStatus.Deactivated]: locale.deactivated,
      [FuelCodesStatus.Redeemed]: locale.redeemed,
    }[fuelCodesStatus] || ""
  );
};

export const prettifyBalance = (type) => {
  return (
    {
      [Balance.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Balance.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Balance.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Balance.Diesel]: locale.diesel,
      [Balance.Peso]: locale.peso,
    }[type] || ""
  );
};

export const prettifyCustomerStatus = (type) => {
  return (
    {
      [CustomerStatus.Active]: "Active",
      [CustomerStatus.Deactivated]: "Deactivated - User Initiated",
      [CustomerStatus.Deleted]: "Deleted",
      [CustomerStatus.Blocked]: "Blocked",
    }[type] || ""
  );
};

export const prettifyAccountStatus = (accountStatus) => {
  return (
    {
      [AccountStatus.Active]: locale.active,
      [AccountStatus.Pending]: locale.pending,
      [AccountStatus.Deactivated]: locale.deactivated,
      [AccountStatus.Expired]: locale.expired,
      [AccountStatus.Inactive]: locale.inactive,
    }[accountStatus] || ""
  );
};

export const prettifyStatus = (status) => {
  return (
    {
      [Status.Active]: locale.active,
      [Status.Terminated]: locale.terminated,
      [Status.Deactivated]: locale.inactive,
    }[status] || ""
  );
};

export const prettifyShoulderedBy = (shoulderedBy) => {
  return (
    {
      [ShoulderedBy.LOCQ]: "LOCQ",
      [ShoulderedBy.OilCo]: "OilCo",
      [ShoulderedBy.StationTypeDependent]: "Station-Type Dependent",
    }[shoulderedBy] || ""
  );
};

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyFuelCodeType = (type) => {
  return (
    {
      [FuelCodeType.DriverSpecific]: locale.driverSpecific,
      [FuelCodeType.VehicleSpecific]: locale.vehicleSpecific,
      [FuelCodeType.DriverVehicleSpecific]: locale.driverVehicleSpecific,
      [FuelCodeType.AnyDriverAnyVehicle]: locale.anyDriverAnyVehicle,
    }[type] || ""
  );
};

export const prettifyVoucherCampaignType = (type) => {
  return (
    {
      [VoucherCampaignType.Generic]: locale.generic,
      [VoucherCampaignType.Unique]: locale.unique,
    }[type] || ""
  );
};

export const prettifyVoucherCampaignCurrency = (type) => {
  return (
    {
      [VoucherCampaignCurrency.Peso]: locale.peso,
      [VoucherCampaignCurrency.Liter]: locale.liter,
      [VoucherCampaignCurrency.Points]: locale.points,
    }[type] || ""
  );
};

export const prettifyRedeemLimitType = (type) => {
  return (
    {
      [RedeemLimitType.Station]: locale.perStation,
      [RedeemLimitType.Universal]: locale.universal,
    }[type] || ""
  );
};

export const prettifyAttributeUploadResponse = (response) => {
  return (
    {
      [AttributeUploadResponse.MissingAttributes]: locale.attributeDoesNotExist,
      [AttributeUploadResponse.MissingUsers]: locale.customerDoesNotExist,
      [AttributeUploadResponse.MissingCohorts]: locale.cohortDoesNotExist,
      [AttributeUploadResponse.UsersUpdated]: locale.usersUpdated,
    }[response] || ""
  );
};

export const prettifyVoucherStatus = (type) => {
  return (
    {
      [VoucherStatus.Unclaimed]: locale.unclaimed,
      [VoucherStatus.Used]: locale.used,
      [VoucherStatus.Expired]: locale.expired,
      [VoucherStatus.AvailableInApp]: locale.availableInApp,
      [VoucherStatus.Void]: locale.void,
      [VoucherStatus.Available]: locale.available,
      [VoucherStatus.Claimed]: locale.claimed,
      [VoucherStatus.Ongoing]: locale.availableInApp,
    }[type] || ""
  );
};

export const prettifyRedemptionStatus = (redemptionStatus) => {
  return (
    {
      [RedemptionStatus.Success]: locale.success,
      [RedemptionStatus.Voided]: locale.voided,
    }[redemptionStatus] || ""
  );
};

export const prettifyPayWithLOCQPayStatus = (paymentStatus) => {
  return (
    {
      [PayWithLOCQPayStatus.Success]: locale.success,
      [PayWithLOCQPayStatus.Voided]: locale.voided,
    }[paymentStatus] || ""
  );
};

export const prettifyPlatformType = (type) => {
  return (
    {
      [PlatformType.PLB]: "PLB",
      [PlatformType.PLC]: "PLC",
    }[type] || ""
  );
};

export const prettifyCurrency = (type) => {
  return (
    {
      [Currency.Peso]: "Peso",
      [Currency.Liter]: "Liter",
      [Currency.Points]: "Points",
    }[type] || ""
  );
};

export const prettifyKYCSubmissionStatus = (type) => {
  return (
    {
      [KYCSubmissionStatus.Pending]: "Pending",
      [KYCSubmissionStatus.Approved]: "Approved",
      [KYCSubmissionStatus.Rejected]: "Rejected",
      [KYCSubmissionStatus.Deleted]: "Deleted",
      [KYCSubmissionStatus.Expired]: "Expired",
    }[type] || ""
  );
};

export const prettifySettlementStatus = (type) => {
  return (
    {
      [SettlementStatus.ForProcessing]: locale.forProcessing,
      [SettlementStatus.ForSettlement]: locale.forSettlement,
      [SettlementStatus.Processed]: locale.processed,
      [SettlementStatus.Cancelled]: locale.cancelled,
    }[type] || ""
  );
};

export const prettifyRFIDStatus = (type) => {
  return (
    {
      [RFIDStatus.Pending]: "Pending",
      [RFIDStatus.Success]: "Success",
      [RFIDStatus.Failed]: "Failed",
      [RFIDStatus.Voided]: "Voided",
    }[type] || ""
  );
};

export const prettifyPayWithPesoStatus = (type) => {
  return (
    {
      [PayWithPesoStatus.Pending]: "Pending",
      [PayWithPesoStatus.Success]: "Success",
      [PayWithPesoStatus.Failed]: "Failed",
      [PayWithPesoStatus.Voided]: "Voided",
    }[type] || ""
  );
};

export const prettifyRFIDPaymentMethod = (type) => {
  return (
    {
      [RFIDPaymentMethod.Card]: "Card",
      [RFIDPaymentMethod.PesoBalance]: "Peso Balance",
    }[type] || ""
  );
};

export const prettifyRFIDType = (type) => {
  return (
    {
      [RFIDType.Autosweep]: "Autosweep",
      [RFIDType.Easytrip]: "Easytrip",
    }[type] || ""
  );
};

export const prettifyOilChangeType = (type) => {
  return (
    {
      [OilChangeType.dieselMonoGrade]: "Diesel Engine Oil Monograde Mineral (40)",
      [OilChangeType.dieselMultiGrade]: "Deomax Multigrade Mineral (15W-40)",
      [OilChangeType.dieselFullySynthetic]: "Diesel Extreme Mako Fully Synthetic (5W-40)",
      [OilChangeType.gasolineMonoGrade]: "Gasoline Engine Oil Monograde Mineral (40)",
      [OilChangeType.gasolineMultiGrade]: "Geo Supreme Multigrade Mineral (20W-40)",
      [OilChangeType.gasolineFullySynthetic]: "Extreme Mako Fully Synthetic (0W-40)",
      [OilChangeType.gasolineFullySynthetic5W40]: "Gasoline Extreme Mako Fully Synthetic (5W-40)",
    }[type] || ""
  );
};

export const prettifyLubeServSite = (lubeServSite) => {
  return (
    {
      [LubeServSite.LubeServAnabuImusCavite]: "LubeServ Anabu Imus Cavite",
      [LubeServSite.LubeServTelabastaganPampanga]: "LubeServ Telabastagan Pampanga",
      [LubeServSite.LubeServCamarinCaloocan]: "Lubeserv Camarin Caloocan - NCR",
      [LubeServSite.LubeServCarmonaCavite]: "Lubeserv Bancal Carmona - Cavite",
      [LubeServSite.LubeServShawBlvd]: "Lubeserv Shaw Boulevard",
      [LubeServSite.LubeServC5Pasig]: "Lubeserv C5 Pasig - NCR",
      [LubeServSite.LubeServBoniCainta]: "Lubeserv A. Boni Cainta - Rizal",
      [LubeServSite.LubeServNorzagarayBulacan]: "Lubeserv Norzagaray - Bulacan",
    }[lubeServSite] || ""
  );
};

export const prettifyVoucherCampaignGroup = (group) => {
  return (
    {
      [VoucherCampaignGroup.SPIAllocation]: "SPI Allocation",
      [VoucherCampaignGroup.SPIAllowance]: "SPI Allowance",
      [VoucherCampaignGroup.SPIObTravel]: "SPI OB Travel",
      [VoucherCampaignGroup.SPISuppliers]: "SPI SUPPLIERS",
      [VoucherCampaignGroup.SPIHonorarium]: "SPI HONORARIUM",
      [VoucherCampaignGroup.Others]: "Others",
    }[group] || ""
  );
};

export const prettifyAuditTrailAction = (type) => {
  return (
    {
      [AuditTrailAction.UpdateFleetDiscount]: "Update Fleet Discount",
      [AuditTrailAction.UpdateFleetRedemptionStaitons]: "Update Fleet Redemption Stations",
      [AuditTrailAction.UpdateFleetRefs]: "Update Fleet Reference Station",
      [AuditTrailAction.ApplyAccountSpecificMovement]: "Apply Account Specific Movement",
    }[type] || ""
  );
};

export const prettifyReportType = (type) => {
  return (
    {
      [ReportType.CashIn]: "Cash In",
      [ReportType.BuyFuel]: "Buy Fuel",
      [ReportType.Redemption]: "Redemption",
      [ReportType.RedemptionItem]: "Redemption",
      [ReportType.PricingHistory]: "Pricing History",
      [ReportType.FuelCode]: "Fuel Code",
      [ReportType.FuelCodes]: "Fuel Codes",
      [ReportType.Mops]: "Mops",
      [ReportType.VoucherClaim]: "Unique Voucher",
      [ReportType.NewCustomerVoucher]: "New Customer Voucher",
      [ReportType.FleetPrices]: "Fleet Prices",
      [ReportType.PriceBuildup]: "Price Buildup",
      [ReportType.FleetAccount]: "Fleet Account",
      [ReportType.StationAccount]: "Station Account",
      [ReportType.CustomerList]: "Customer List",
      [ReportType.VoucherCampaign]: "Voucher Campaign",
      [ReportType.DiscountCampaign]: "Discount Campaign",
      [ReportType.RfidTopup]: "Rfid Topup",
      [ReportType.Payment]: "Payment",
      [ReportType.MerchantPayment]: "Merchant Payment",
      [ReportType.KYCSubmission]: "KYC Submission",
      [ReportType.PriceMovement]: "Price Movement",
      [ReportType.UniqueVoucher]: "Unique Voucher",
      [ReportType.SupplierWPP]: "Supplier WPP",
      [ReportType.PricingChanges]: "Pricing Changes",
      [ReportType.LubeservBookings]: "LubeServ on Wheels",
      [ReportType.StationNegotiatedDiscount]: "DOX Negotiated Discount",
      [ReportType.LubeservOnSite]: "Lubeserv on Site",
      [ReportType.LubeservOnWheels]: "Lubeserv on Wheels",
      [ReportType.LOCQPayDiscountCampaign]: "LOCQPay Discount Campaign",
    }[type] || ""
  );
};

export const prettifyVoidTransactionValue = (value) => {
  return (
    {
      [VoidTransactionValue.WrongInputByUser]: locale.mischargeWrongInputByUser,
      [VoidTransactionValue.WrongVolumeInput]: locale.mischargeWrongVolumeInput,
      [VoidTransactionValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyVoidPaymentValue = (value) => {
  return (
    {
      [VoidPaymentValue.WrongAmountInput]: locale.michargedWrongAmountInput,
      [VoidPaymentValue.DidNotIntendToPayViaPeso]: locale.michargedDidNotIntendtoPayViaPeso,
      [VoidPaymentValue.Others]: locale.others,
    }[value] || locale.others
  );
};

export const prettifyVoucherType = (value) => {
  return (
    {
      [VoucherType.PesoVoucherCode]: locale.pesoVoucherCode,
      [VoucherType.PesoDiscount]: locale.pesoDiscountOnBuyFuelVoucherCode,
      [VoucherType.LOCQPayPesoDiscountVoucher]: locale.discountOnScanToPay,
      [VoucherType.LOCQPayLubesDiscountVoucher]: locale.discountLubesOnScanToPay,
      [VoucherType.ThirdPartyVoucher]: locale.thirdPartyVoucher,
    }[value] || ""
  );
};

export const prettifyVoucherStatusType = (value) => {
  return (
    {
      [VoucherType.PesoVoucherCode]: locale.pesoVoucherCode,
      [VoucherType.PesoDiscount]: "Peso Discount",
      [VoucherType.ThirdPartyVoucher]: locale.thirdPartyVoucher,
      [VoucherType.LOCQPayPesoDiscountVoucher]: locale.discountOnScanToPay,
      [VoucherType.LOCQPayLubesDiscountVoucher]: locale.discountLubesOnScanToPay,
    }[value] || ""
  );
};

export const prettifyVoucherOption = (value) => {
  return (
    {
      [VoucherTypeOptions.PesoVoucherCodeOneTime]: "Peso Voucher (One-Time Claim)",
      [VoucherTypeOptions.PesoVoucherCodeAutoSeed]: "Peso Voucher (Auto-Seed)",
      [VoucherTypeOptions.PesoVoucherCodeMultiClaim]: "Peso Voucher (Multi-Claim Generic)",
      [VoucherTypeOptions.PesoDiscountCodeOneTime]: "Peso Discount (One-Time Claim)",
      [VoucherTypeOptions.PesoDiscountCodeAutoSeed]: "Peso Discount (Auto-Seed)",
      [VoucherTypeOptions.PesoDiscountCodeMultiClaim]: "Peso Discount (Multi-Claim Generic)",
      [VoucherTypeOptions.ThirdPartyCodeOneTime]: "Third-Party Voucher (One-Time Claim)",
      [VoucherTypeOptions.ThirdPartyCodeCodeAutoSeed]: "Third-Party Voucher  (Auto-Seed)",
      [VoucherTypeOptions.ThirdPartyCodeCodeMultiClaim]:
        "Third-Party Voucher  (Multi-Claim Generic)",
    }[value] || ""
  );
};

export const prettifyVoucherClaimMethod = (value) => {
  return (
    {
      [ClaimMethod.AutoCashIn]: locale.autoCashIn,
      [ClaimMethod.SeedInVoucherTray]: locale.seedInVoucherTray,
    }[value] || ""
  );
};

export const prettifyThirdPartyVoucherCode = (value) => {
  return (
    {
      [ThirdPartyVoucherCode.ManualInput]: locale.manualInput,
      [ThirdPartyVoucherCode.UploadCsv]: locale.uploadCsv,
    }[value] || ""
  );
};

export const prettifyBuyFuelStatus = (value) => {
  return (
    {
      [BuyFuelStatus.Success]: locale.success,
      [BuyFuelStatus.Voided]: locale.voided,
    }[value] || ""
  );
};

export const prettifyFleetAccountType = (value) => {
  return (
    {
      [FleetAccountType.FleetAccount]: "Fleet Account",
      [FleetAccountType.KeyAccount]: "Key Account",
    }[value] || ""
  );
};

export const prettifyIntroductoryPromoOffer = (value) => {
  return (
    {
      [IntroductoryPromoOffer.Basic]: "Basic Introductory Promo",
      [IntroductoryPromoOffer.GlobalLinker]: "Global Linker Referral Introductory Promo",
      [IntroductoryPromoOffer.Mias]: "April 2023 MIAS Referral Introductory Promo",
      [IntroductoryPromoOffer.NoIntroductoryPromo]: "No Introductory Promo",
      [IntroductoryPromoOffer.IntroductoryPlbHedging]: "Cash-In 30k Get 3k Back PLB Hedging Promo",
      [IntroductoryPromoOffer.IntroductoryPlbLocqpay]: "Cash-In 30k Get 5k Back PLB LOCQPay Promo",
    }[value] || ""
  );
};

export const prettifyVoucherTypeSetting = (value) => {
  return (
    {
      [VoucherTypeSetting.OneTimeClaim]: "One-Time Claim",
      [VoucherTypeSetting.AutoSeed]: "Auto-Seed",
      [VoucherTypeSetting.MultiClaimGeneric]: "Multi-Claim Generic",
      [VoucherTypeSetting.MultiCodeGeneric]: "Multi-code Generic",
    }[value] || ""
  );
};

export const prettifyRole = (role) => {
  return (
    {
      [Role.PortalAdmin]: locale.admin,
      [Role.Accounting]: locale.accounting,
      [Role.SalesPartnerships]: locale.salesPartnerships,
      [Role.DataAnalyst]: locale.dataAnalyst,
    }[role] || ""
  );
};

export const prettifyStationClass = (value) => {
  return (
    {
      [StationClass.EFTI]: "EFTI",
      [StationClass.GUI]: "GUI",
      [StationClass.SCI]: "SCI",
      [StationClass.NA]: "N/A",
    }[value] || ""
  );
};

export const prettifyChargingStatus = (value) => {
  return (
    {
      [ChargingStatus.ForCharging]: locale.forCharging,
      [ChargingStatus.Waived]: locale.waived,
    }[value] || ""
  );
};

export const prettifyPaymentStatus = (value) => {
  return (
    {
      [PaymentStatus.Paid]: locale.paid,
      [PaymentStatus.Pending]: locale.pending,
      [PaymentStatus.Waived]: locale.waived,
    }[value] || ""
  );
};

export const prettifyPlbType = (value) => {
  return (
    {
      [PLBTypes.PLB_HEDGING]: "PLB Hedging",
      [PLBTypes.PLB_LOCQPAY]: "PLB LOCQPay",
      [PLBTypes.PLB_LOCQPAY_MANUAL_PO]: "PLB LOCQPay with Manual P.O",
      [PLBTypes.PLB_LOCQPAY_MANUAL_PO_CREDIT]: "PLB LOCQPay Credit",
    }[value] || ""
  );
};

export const prettifyPlbPostedBy = (value) => {
  return (
    {
      [PLBPostedBy.ACCOUNT]: "Account",
      [PLBPostedBy.DEALER_OPERATOR]: "Dealer/Operator",
      [PLBPostedBy.SEAOIL]: "SEAOIL",
    }[value] || ""
  );
};

export const prettifyInvoicingSetUp = (value) => {
  return (
    {
      [InvoicingSetUp.SINGLE_INVOICING]: "Single Invoicing",
      [InvoicingSetUp.DEFAULT]: "Default",
    }[value] || ""
  );
};

export const prettifyVolumeCap = (volumeCap) => {
  return (
    {
      [VolumeCap.DAY]: "Daily",
      [VolumeCap.EntireCampaign]: "Entire Campaign",
    }[volumeCap] || ""
  );
};

export const prettifyCustomerRemarks = (remarks) => {
  return (
    {
      [CustomerStatusRemarks.CustomerRequest]: "Customer Request",
      [CustomerStatusRemarks.Fraud]: "Fraud",
      [CustomerStatusRemarks.Others]: "Others",
    }[remarks] || remarks
  );
};

export const prettifyFleetCardType = (cardType) => {
  return (
    {
      [FleetCardType.DriverSpecific]: "Driver Specific",
      [FleetCardType.VehicleSpecific]: "Vehicle Specific",
      [FleetCardType.DriverVehicleSpecific]: "Driver & Vehicle Specific",
    }[cardType] || "Any Driver, Any Vehicle"
  );
};
