import LabelAndValue from "components/commons/label-and-value/label-and-value";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";
import { capitalize, parseNumber } from "utils";
import locale from "localization";
import { formatNumber, formatDate, formatAmount } from "utils";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherClaimMethod,
  prettifyVoucherStatusType,
  prettifyVoucherTypeSetting,
} from "utils/pretty.utils";
import { CampaignStatus, DateTime, Path, Role } from "enums";
import { getVoucherTypeAndSubType } from "enums/voucher-type-options";
import { Image, Pill, PopOverMenu, Table, Title } from "components/commons";
import { MoreVert } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import VoucherRecipients from "enums/voucher-recipients";
import { GuyWithMagGlass } from "images";
import ThirdPartyVoucherCode from "enums/third-party-voucher-code";

export const mapDataToList = ({
  data,
  history,
  updateVoucherRequest,
  confirmModal,
  getVoucherCampaignHistoryRequest,
  role
}) => {
  const {
    voucherCampaignId,
    campaignName,
    voucherPrefix,
    voucherCampaignGroup,
    voucherType,
    claimMethod,
    totalVoucherValue,
    totalVoucherCount,
    totalClaimedVoucherValue,
    totalClaimedVoucherCount,
    totalUsedVoucherCount,
    totalUsedVoucherValue,
    startDate,
    endDate,
    voucherSubType,
    createdByEmail,
    createdBy,
    createdAt,
    updatedAt,
    status,
  } = data;

  // const isPesoDiscount = voucherType === VoucherType.PesoDiscount;

  const actionOptions = [
    {
      content: "View vouchers",
      onClick: () => {
        history.push(Path.CampaignVouchers, {
          search: campaignName,
        });
      },
      
    },
    {
      content: "View details",
      onClick: () => {
        history.push(Path.ViewVoucherCampaignId(voucherCampaignId));
      },
    },
  ];
  if (status !== CampaignStatus.Cancelled) {
    actionOptions.push({
      content: locale.editDetails,
      disabled: role === Role.Accounting,
      onClick: () => {
        history.push(Path.EditVoucherCampaignId(voucherCampaignId));
      },
    });
  }

  if (![CampaignStatus.Cancelled, CampaignStatus.Ended].includes(status)) {
    actionOptions.push({
      content: locale.cancel,
      disabled: role === Role.Accounting,
      onClick: () => {
        confirmModal.show({
          refresh: true,
          title: `${locale.cancelCampaign}?`,
          content: locale.populate(locale.cancelCampaignDesc, [campaignName]),
          primary: {
            text: locale.yesCancelIt,
            onClick: async () => {
              await updateVoucherRequest.request({
                action: "cancel",
                voucherCampaignId,
              });
            },
          },
          secondary: {
            text: locale.cancel,
            onClick: async () => {
              confirmModal.close();
            },
          },
        });
      },
    });
  }

  if (status === CampaignStatus.Cancelled) {
    actionOptions.push({
      content: locale.reactivate,
      disabled: role === Role.Accounting,
      onClick: () => {
        confirmModal.show({
          refresh: true,
          title: `${locale.reactivateCampaign}?`,
          content: locale.populate(locale.reactivateCampaignDesc, [campaignName]),
          primary: {
            text: locale.yesReactivateIt,
            onClick: async () => {
              await updateVoucherRequest.request({
                action: "reactivate",
                voucherCampaignId,
              });
            },
          },
          secondary: {
            text: locale.cancel,
            onClick: async () => {
              confirmModal.close();
            },
          },
        });
      },
    });
  }
  actionOptions.push({
    content: locale.actionsHistory,
    onClick: async () => {
      confirmModal.show({
        title: `${locale.actionsHistory}`,
        content: (
          <div>
            <CircularProgress />
          </div>
        ),
        primary: null,
        secondary: null,
      });
      const res = await getVoucherCampaignHistoryRequest.request({
        voucherCampaignId,
      });
      const data = [];
      res.forEach(({ action, updatedBy, updatedByEmail, updatedAt }) => {
        const map = new Map();
        map.set("action", capitalize(action));
        map.set("modifiedBy", updatedByEmail || updatedBy);
        map.set("date", formatDate(updatedAt, DateTime.G));
        data.push(map);
      });
      confirmModal.show({
        title: `${locale.actionsHistory}`,
        content: (
          <div>
            {data.length ? (
              <Table
                columns={[
                  {
                    key: "action",
                    text: locale.action,
                    style: {
                      minWidth: "70px",
                      textAlign: "left",
                    },
                  },
                  {
                    key: "modifiedBy",
                    text: locale.modifiedBy,
                    style: {
                      minWidth: "70px",
                      textAlign: "left",
                    },
                  },
                  {
                    key: "date",
                    text: locale.date,
                    style: {
                      minWidth: "70px",
                      textAlign: "left",
                    },
                  },
                ]}
                data={data}
              />
            ) : (
              <div>
                <Image src={GuyWithMagGlass} />
                <Title small>{locale.sorryNoResultFound}</Title>
              </div>
            )}
          </div>
        ),
        primary: null,
        secondary: null,
      });
    },
  });

  return {
    voucherCampaignId: <div>{voucherCampaignId}</div>,
    name: campaignName,
    voucherPrefix,
    campaignGroup: prettifyVoucherCampaignGroup(voucherCampaignGroup),
    voucherType: `${prettifyVoucherStatusType(voucherType)} ${
      voucherSubType ? `(${prettifyVoucherTypeSetting(voucherSubType)})` : ""
    }`.trim(),
    claimMethod: prettifyVoucherClaimMethod(claimMethod),
    totalValue: (
      <LabelAndValue label={formatNumber(totalVoucherCount, 0)}>
        {voucherType === VoucherType.PesoDiscount ||
        voucherType === VoucherType.LOCQPayPesoDiscountVoucher
          ? "--"
          : totalVoucherValue
          ? formatAmount(totalVoucherValue)
          : "--"}
      </LabelAndValue>
    ),
    totalClaimed: (
      <LabelAndValue label={formatNumber(totalClaimedVoucherCount, 0)}>
        {voucherType === VoucherType.PesoDiscount ||
        voucherType === VoucherType.LOCQPayPesoDiscountVoucher
          ? "--"
          : totalClaimedVoucherValue
          ? formatAmount(totalClaimedVoucherValue)
          : "--"}
      </LabelAndValue>
    ),
    totalUsed: (
      <LabelAndValue label={formatNumber(totalUsedVoucherCount, 0)}>
        {totalUsedVoucherValue ? formatAmount(totalUsedVoucherValue) : "--"}
      </LabelAndValue>
    ),
    status: (
      <LabelAndValue label={formatDate(updatedAt, DateTime.G)}>
        <Pill
          style={{
            marginBottom: "10px",
          }}
          grass={status === CampaignStatus.Ongoing}
          sky={status === CampaignStatus.Upcoming}
          cement={status === CampaignStatus.Ended}
          deepRed={status === CampaignStatus.Cancelled}
        >
          {capitalize(status)}
        </Pill>
      </LabelAndValue>
    ),
    date: (
      <LabelAndValue label={formatDate(endDate, DateTime.G)}>
        {formatDate(startDate, DateTime.G)}
      </LabelAndValue>
    ),
    campaignCreator: (
      <LabelAndValue label={formatDate(createdAt, DateTime.G)}>
        {createdByEmail?.trim() || createdBy || "-"}
      </LabelAndValue>
    ),
    actions: (
      <PopOverMenu options={actionOptions}>
        <MoreVert />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { campaignCreators = {}, status, voucherType, ...fs } = filterState;
  const selectedCampaignCreators = campaignCreators?.value?.map((item) => {
    return item.value;
  });
  return {
    ...fs,
    status: status.join(","),
    voucherType: voucherType === "all" ? "" : voucherType,
    campaignCreators: campaignCreators.isAll ? "" : selectedCampaignCreators.join(","),
  };
};

export const mapApiRequest = ({ voucherType: type = [], ...param }) => {
  const voucher = getVoucherTypeAndSubType(type) || {};

  return {
    ...param,
    voucherType: voucher.type,
    voucherSubType: voucher.subType,
  };
};

export const mapFormToRequest = (form) => {
  const {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    campaignGroup: voucherCampaignGroup,
    voucherPrefix,
    campaignDuration = {},
    voucherValue,
    claimMethod,
    voucherTypeSetting: voucherSubType,
    voucherAmount,
    campaignImage,
    recipients,
    uploadCSV,
    uploadThirdPartyVoucherCSV,
    thirdPartyVoucherCode,
    voucherDiscountValue,
    minimumSpend,
    discountCap,
    requiredReferenceStation,
    voucherValueMultiClaim,
    claimLimit,
    discountValue,
    voucherQuantity,
    billedTo,
    billedToOthers,
    remarks,
    multiCodeQuantity,
    thirdPartyVoucherAutoSeed,
    thirdPartyVoucherValue,
  } = form;
  const { startDate, endDate } = campaignDuration;

  const obj = {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    voucherSubType,
    voucherCampaignGroup,
    startDate: formatDate(startDate, DateTime.I),
    endDate: formatDate(endDate, DateTime.I),
    voucherPrefix,
    claimMethod,
    campaignImage,
    billedTo,
    remarks,
  };

  if (
    [VoucherTypeSetting.OneTimeClaim].includes(voucherSubType) &&
    voucherType === VoucherType.PesoVoucherCode
  ) {
    obj.voucherValues = voucherValue.map((item) => {
      return {
        value: parseNumber(item.amount.value),
        quantity: parseNumber(item.quantity.value),
      };
    });
  }

  if (
    (VoucherTypeSetting.MultiClaimGeneric === voucherSubType &&
      voucherType === VoucherType.PesoVoucherCode) ||
    (VoucherTypeSetting.MultiCodeGeneric === voucherSubType &&
      voucherType === VoucherType.LOCQPayPesoDiscountVoucher)
  ) {
    obj.voucherValues = [
      {
        quantity: parseNumber(claimLimit),
        value: parseNumber(voucherValueMultiClaim),
        multiCodeQuantity: parseNumber(multiCodeQuantity) ?? null,
      },
    ];
  }
  if (
    voucherType === VoucherType.PesoVoucherCode &&
    voucherSubType === VoucherTypeSetting.AutoSeed &&
    recipients === VoucherRecipients.SelectedAccounts
  ) {
    obj.voucherValues = voucherAmount.map((item) => {
      return {
        value: item?.amount?.value ? parseNumber(item.amount.value) : 0,
        quantity: item?.quantity?.value ? parseNumber(item.quantity.value) : 0,
        mobileNumber: `+63${item.mobileNumber.value}`,
      };
    });
  }

  if (
    (voucherSubType === VoucherTypeSetting.OneTimeClaim ||
      voucherSubType === VoucherTypeSetting.MultiClaimGeneric) &&
    voucherType === VoucherType.ThirdPartyVoucher &&
    thirdPartyVoucherCode === ThirdPartyVoucherCode.ManualInput
  ) {
    obj.voucherValues = thirdPartyVoucherValue.map((item) => {
      return {
        quantity: parseNumber(
          voucherSubType === VoucherTypeSetting.OneTimeClaim
            ? item.quantity.value
            : multiCodeQuantity
        ),
        thirdPartyVoucherCode: item.thirdPartyVoucherCode?.value,
      };
    });
  }

  if (
    voucherSubType === VoucherTypeSetting.AutoSeed &&
    voucherType === VoucherType.ThirdPartyVoucher &&
    recipients === VoucherRecipients.SelectedAccounts
  ) {
    obj.voucherValues = thirdPartyVoucherAutoSeed.map((item) => {
      return {
        quantity: parseNumber(item.quantity.value),
        mobileNumber: `+63${item.mobileNumber.value}`,
        thirdPartyVoucherCode: item.thirdPartyVoucherCode.value,
      };
    });
  }

  if (
    voucherSubType === VoucherTypeSetting.AutoSeed &&
    recipients === VoucherRecipients.UploadCsv &&
    voucherType !== VoucherType.ThirdPartyVoucher
  ) {
    obj.data = uploadCSV[0];
    obj.isCsv = true;
    obj.isCsvUpload = true;
  }

  if (
    voucherType === VoucherType.ThirdPartyVoucher &&
    (recipients === VoucherRecipients.UploadCsv ||
      thirdPartyVoucherCode === ThirdPartyVoucherCode.UploadCsv)
  ) {
    obj.data = uploadThirdPartyVoucherCSV;
    obj.isCsv = true;
    obj.isCsvUpload = true;
  }
  if (
    voucherType === VoucherType.PesoDiscount ||
    voucherType === VoucherType.LOCQPayPesoDiscountVoucher
  ) {
    obj.discountValueType = voucherDiscountValue;
    obj.minimumSpend = parseNumber(minimumSpend);

    obj.referenceStationIds = requiredReferenceStation.map(({ value }) => value).join(",");
    obj.discountCap = parseNumber(discountCap);
    obj.quantity =
      voucherSubType === VoucherTypeSetting.MultiClaimGeneric ||
      voucherSubType === VoucherTypeSetting.MultiCodeGeneric
        ? parseNumber(claimLimit)
        : parseNumber(voucherQuantity);
    obj.voucherQuantity =
      voucherSubType === VoucherTypeSetting.MultiClaimGeneric ||
      voucherSubType === VoucherTypeSetting.MultiCodeGeneric
        ? parseNumber(claimLimit)
        : parseNumber(voucherQuantity);
    if (voucherSubType !== VoucherTypeSetting.AutoSeed) {
      obj.voucherValues = [
        {
          quantity:
            voucherSubType === VoucherTypeSetting.MultiClaimGeneric ||
            voucherSubType === VoucherTypeSetting.MultiCodeGeneric
              ? parseNumber(claimLimit)
              : parseNumber(voucherQuantity),
          value: parseNumber(discountValue),
          multiCodeQuantity: parseNumber(multiCodeQuantity) ?? null,
        },
      ];
    } else {
      obj.voucherValues = voucherAmount.map((item) => {
        return {
          quantity: item?.quantity?.value ? parseNumber(item.quantity.value) : 0,
          mobileNumber: `+63${item.mobileNumber.value}`,
        };
      });
    }

    obj.discountValue = parseNumber(discountValue);
  }

  if (billedToOthers) {
    obj.billedTo = billedToOthers;
  }

  return {
    ...obj,
  };
};

export const mapFormToRequestEdit = (form) => {
  const {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    campaignGroup: voucherCampaignGroup,
    voucherPrefix,
    campaignDuration = {},
    claimMethod,
    voucherTypeSetting: voucherSubType,
    campaignImage,
    billedTo,
    billedToOthers,
    remarks,
  } = form;
  const { startDate, endDate } = campaignDuration;

  const obj = {
    campaignName,
    shortDescription,
    longDescription,
    voucherImage,
    voucherType,
    voucherSubType,
    voucherCampaignGroup,
    startDate: formatDate(startDate, DateTime.I),
    endDate: formatDate(endDate, DateTime.I),
    voucherPrefix,
    claimMethod,
    campaignImage,
    billedTo,
    remarks,
  };

  if (billedToOthers) {
    obj.billedTo = billedToOthers;
  }

  return {
    ...obj,
  };
};
