import { ApiPath } from "enums";
import { Get, Post } from "../utils";

export const validateFuelCardsByCardNumbers = async ({ ...query }) => {
  const res = await Get(`${ApiPath.CardManagement}/card`, query);
  return res.data;
};

export const validateShortNamesByShortName = async ({ ...query }) => {
  const res = await Get(`${ApiPath.ShortName}`, query);
  return res.data;
};

export const importFuelCards = async ({ ...query }) => {
  const res = await Post(`${ApiPath.CardManagement}/batch`, query);
  return res.data;
};
